<template>
    <site-header-v2 :phone="phone"/>
    <gradient-delimiter height="32px" start-color="#e6e6e7" end-color="#f6f7f8"/>
    <div class="bg-[#f6f7f8]">
        <div class="container">
            <h1 class="pt-16 md:w-2/3 text-center mx-auto">Дебетовая карта Black c&nbsp;кэшбэком рублями на&nbsp;всё</h1>
            <div class="mt-4 text-center">Выберите категории повышенного кэшбэка при оформлении карты до 31 октября.<br>Получайте 10% вместо 5% весь октябрь</div>
            <yellow-button class="mx-auto block mt-8 mb-4" @click="gotoForm">Оформить карту</yellow-button>

            <div class="grid grid-cols-3 sm:grid-cols-1 gap-12 mt-12">
                <round-block-icon title="Кэшбэк 10% вместо 5%" :icon="require('@/assets/images/i1-1.svg')">
                    За покупки в выбранных вами категориях: одежда и обувь, аптеки, топливо, рестораны, фастфуд и не только
                </round-block-icon>
                <round-block-icon title="0 ₽ за обслуживание" :icon="require('@/assets/images/i1-2.svg')">
                    Если хранить от 50 000 ₽ на карте, вкладах, накопительных счетах и в инвестициях. Иначе — 99 ₽ в месяц
                </round-block-icon>
                <round-block-icon background="#333333" text-color="#ffffff" title="Платежи и переводы без комиссии" :icon="require('@/assets/images/i1-3.svg')">
                    Переводы на карты других банков, оплата ЖКУ, штрафов ГИБДД, связи — без комиссии с картой Т‑Банка
                </round-block-icon>
            </div>

            <h2 class="mt-12 text-center">Получите Black уже сегодня</h2>
            <div class="grid grid-cols-3 sm:grid-cols-1 gap-12 my-16">
                <step-block icon="1">
                    Заполните онлайн-заявку — посещать банк не нужно. Доставим карту бесплатно в удобное время
                </step-block>
                <step-block icon="2">
                    Выберите в заявке категории повышенного кэшбэка: аптеки, фастфуд, рестораны и другие
                </step-block>
                <step-block icon="3">
                    До конца ноября получайте кэшбэк 10% в этих категориях. Пользуйтесь банком в приложении и личном кабинете
                </step-block>
            </div>
        </div>

        <frame-form :url="form.url"/>

        <div class="container">
            <h2 class="text-center mt-24 mb-10">Дебетовая карта «Мир» с&nbsp;кэшбэком</h2>
            <div class="grid grid-cols-2 gap-12 sm:grid-cols-1">
                <round-block-icon title="Снятие без комиссии" :icon="require('@/assets/images/i2-1.svg')" background="#eaecee">
                    До 500 000 ₽ в банкоматах Т-Банка, от 3 000 до 100 000 ₽ в других банкоматах
                </round-block-icon>
                <round-block-icon title="Платежи за ЖКУ и другие услуги без комиссии" :icon="require('@/assets/images/i2-2.svg')" background="#eaecee">
                    Оплачивайте связь и интернет, детский сад и учебу детей без лишних трат
                </round-block-icon>
            </div>
            <div class="grid grid-cols-3 gap-12 sm:grid-cols-1 mt-12">
                <div class="rounded-3xl col-span-2 bg-[#eaecee] h-full flex flex-col justify-end">
                    <img src="@/assets/images/pic.webp" alt="pic">
                </div>
                <div>
                    <h3>Бесплатное пополнение и&nbsp;переводы</h3>
                    <div class="mt-2">
                        Пополняйте без комиссии с карт других банков и в банкоматах Т‑Банка. Переводите без комиссии на карты других банков через Систему быстрых платежей
                    </div>
                    <h3 class="mt-6">Карты действуют в&nbsp;7&nbsp;странах</h3>
                    <div class="mt-2">
                        Можно пользоваться заграницей в Абхазии, Беларуси, Вьетнаме, Казахстане, на Кубе, в Таджикистане и Южной Осетии
                    </div>
                    <h3 class="mt-6">Подходит для получения выплат от&nbsp;государства</h3>
                    <div class="mt-2">
                        Пенсию, пособия и зарплату в бюджетной сфере можно получать прямо на карту
                    </div>
                </div>
            </div>
            <div class="text-center">
                <yellow-button @click="gotoForm()" class="mt-12 mb-24">Оформить карту</yellow-button>
            </div>

        </div>
    </div>
    <site-footer :phone="phone" disclaimer="" copyright="" bank-links="" :product-links="pLinks"></site-footer>
</template>

<script>

import '@/assets/tailwind.css';
import GradientDelimiter from "@/components/gradient-delimiter";
import SiteFooter from "@/components/site-footer-v2";
import YellowButton from "@/components/yellow-button.vue";
import SiteHeaderV2 from "@/components/site-header-v2.vue";
import bridge from "@vkontakte/vk-bridge";
import FrameForm from "@/components/frame-form.vue";
import RoundBlockIcon from "@/components/round-block-icon.vue";
import StepBlock from "@/components/step-block.vue";

export default {
    name: 'App',
    components: {StepBlock, RoundBlockIcon, FrameForm, SiteHeaderV2, YellowButton, GradientDelimiter, SiteFooter},

    data: () => ({
        urlParams: '',
        urlData: null,
        phone: process.env.VUE_APP_PHONE,
        form: {
            //url: 'https://www.tbank.ru/cards/debit-cards/tinkoff-black/iframe/form/vk-miniapp/',
            url: 'https://www.tbank.ru/cards/debit-cards/tinkoff-black/iframe/form/',
        },
        pLinks: [
            {text: 'Правила программы лояльности', link: 'https://acdn.tinkoff.ru/static/documents/loyalty-program-black.pdf'},
            {text: 'Тарифный план', link: 'https://acdn.tinkoff.ru/static/documents/debit-tariffs-tps_3_0.pdf'},
            {text: '«Т-Банк Таргет»', link: 'https://acdn.tinkoff.ru/static/documents/loyalty-program-target_rules.pdf'},
            {text: 'Курсы валют', link: 'https://www.tinkoff.ru/about/exchange/'},
            {text: 'Условия подписки', link: 'https://acdn.tinkoff.ru/static/documents/docs-tinkoff-bundle-subscription-terms.pdf'},
            {text: 'Условия акции', link: 'https://acdn.t-static.ru/static/documents/promo-cashback-10-percent-categories-your-choice-october-2024.pdf'},
        ]
    }),

    mounted() {
        bridge.send("VKWebAppInit");
    },
    created() {
        //this.urlParams = window.location.hash;
        this.urlParams = window.location.href;
    },
    methods: {
        gotoForm() {
            window.scrollTo({
                top: document.getElementById('form-iframe').offsetTop,
                left: 0,
                behavior: "smooth",
            });
        }
    }
}

</script>

<style lang="scss">

@font-face {
    font-family: 'TinkoffSans';
    src: url('assets/fonts/TinkoffSans.woff2');
    font-weight: 100;
}

@font-face {
    font-family: 'TinkoffSans';
    src: url('assets/fonts/TinkoffSans_Medium.woff2') format('woff2');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'TinkoffSans';
    src: url('assets/fonts/TinkoffSans_Bold.woff2') format('woff2');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'haas';
    src: url('assets/fonts/NeueHaasUnicaW1G-Regular.woff2');
}

@font-face {
    font-family: 'Font Awesome';
    font-style: normal;
    font-weight: 400;
    font-display: block;
    src: url("assets/fonts/fa-regular-400.eot");
    src: url("assets/fonts/fa-regular-400.eot?#iefix") format("embedded-opentype"), url("assets/fonts/fa-regular-400.woff2") format("woff2"), url("assets/fonts/fa-regular-400.woff") format("woff"), url("assets/fonts/fa-regular-400.ttf") format("truetype");
}

@font-face {
    font-family: 'Font Awesome';
    font-style: normal;
    font-weight: 900;
    font-display: block;
    src: url("assets/fonts/fa-solid-900.eot");
    src: url("assets/fonts/fa-solid-900.eot?#iefix") format("embedded-opentype"), url("assets/fonts/fa-solid-900.woff2") format("woff2"), url("assets/fonts/fa-solid-900.woff") format("woff"), url("assets/fonts/fa-solid-900.ttf") format("truetype");
}

body {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-weight: 400;
    font-size: 15px;
    font-family: haas, pragmatica, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, sans-serif;
    color: #333333;
    font-kerning: normal;
    text-rendering: optimizeLegibility;
}

.container {
    @media screen and (max-width: 575px) {
        padding-left: 10px;
        padding-right: 10px;
    }
}

h1, h2, h3 {
    font-family: 'TinkoffSans', sans-serif;
    font-weight: 400;
}

h1, h2 {
    font-weight: 700;
    font-variant-ligatures: no-contextual;
    font-size: 44px;
    line-height: 48px;
    letter-spacing: 0;
    @media screen and (max-width: 575px) {
        font-size: 32px;
        line-height: 35px;
    }
}

h2 {
    @media screen and (max-width: 575px) {
        font-size: 28px;
        line-height: 32px;
    }
}

h3 {
    font-size: 24px;
    line-height: 30px;
}

.header {
    @apply mt-24 w-2/3 mx-auto text-center;
}

::selection {
    background: rgba(255, 221, 45, .32) !important;
    color: rgba(0, 0, 0, .8) !important;
}

.numbers {
    font-family: TinkoffSans, pfhighway, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif;
    font-weight: 100;
}

</style>
